body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code { font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

nav { font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande" sans-serif; }

ul {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande" sans-serif;
    list-style:none;
    margin-left: 0;
    padding-left: 1em;
}

.navbar-light .navbar-toggler, .white-brand {
    filter: invert(100%);
    border-width: 0px;
}

.card-body a:hover {
    color:#6bbcff;
    text-decoration: underline;
}

.title-link {
    color:#6bbcff;
}

.container {
    margin-top:15px;
    margin-bottom:15px;
}

.l-nav-spacer, .r-nav-spacer {
    margin-right: calc(50px + (50 - 50) * ((100vw - 300px) / (3840 - 320)));
}

.title-image {
    width: calc(200px + (300 - 200) * ((100vw - 300px) / (3840 - 320)));
    height: calc(200px + (300 - 200) * ((100vw - 300px) / (3840 - 320)));
    border-radius: 50%;
} 

.header {
    font-family: Lora;
    font-weight:bold;
    color: #216834;
    /*calc([min size] + ([max size] - [min size]) * ((100vw - [min vw]) / ([max vw] - [min vw])))*/
    font-size: calc(45px + (90 - 45) * ((100vw - 300px) / (3840 - 320)));
    margin: -10px;
    margin-bottom: -20px;
}

.sub-header {
    font-family: "Roboto Mono";
    font-size: calc(18px + (40 - 18) * ((100vw - 300px) / (3840 - 320)));
}

.contact {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
    Helvetica, Arial, "Lucida Grande" sans-serif;
    font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (3840 - 320)));
}

.section-header {
    font-family: Lora;
    color: #216834;
    font-size: calc(18px + (44 - 18) * ((100vw - 300px) / (3840 - 320)));
    font-weight:bold;
}

.sub-section-title {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
    Helvetica, Arial, "Lucida Grande" sans-serif;
    font-size: calc(13px + (34 - 13) * ((100vw - 300px) / (3840 - 320)));
    font-weight: bold;
}

.sub-section-location {
    font-family: "Droid Sans";
    font-size: calc(13px + (34 - 13) * ((100vw - 300px) / (3840 - 320)));
    color:#696d72;
}

.sub-section-date {
    font-family: "Roboto Mono";
    font-size: calc(12px + (30 - 12) * ((100vw - 300px) / (3840 - 320)));
    color:#696d72;
}

.sub-section-bullet {
    font-size: calc(12px + (29 - 12) * ((100vw - 300px) / (3840 - 320)));
}

.contact-spacer {
    margin-right: calc(5px + (5 - 5) * ((100vw - 300px) / (3840 - 320)));
    margin-left: calc(5px + (25 - 5) * ((100vw - 300px) / (3840 - 320)));
}

.contact-text {
    font-size: calc(12px + (34 - 12) * ((100vw - 300px) / (3840 - 320)));
}

.sort-button-text, .nav-text {
    font-size:calc(14px + (30 - 14) * ((100vw - 300px) / (3840 - 320))) !important;
}

.sort-spacer {
    margin-bottom:-20px;
}

.card {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
    Helvetica, Arial, "Lucida Grande", sans-serif;
}

.card-date {
    font-family: Roboto Mono;
    font-size:calc(20px + (40 - 20) * ((100vw - 300px) / (3840 - 320)));
}

.card-title {
    font-size:calc(18px + (36 - 18) * ((100vw - 300px) / (3840 - 320)));
    font-weight:bold;
}

.project-description {
    font-size:calc(14px + (30 - 14) * ((100vw - 300px) / (3840 - 320)));
}

.project-technology {
    font-size:calc(12px + (28 - 12) * ((100vw - 300px) / (3840 - 320)));
}

.footer {
    font-size:calc(12px + (30 - 12) * ((100vw - 300px) / (3840 - 320)));
}

.card .arduino {
    background-image: linear-gradient(180deg,#00979d,#00596d);
}
.card .cpp {
    background-image: linear-gradient(180deg,#ffb2fb,#9e2d96); 
}

.card .cron {
    background-image: linear-gradient(180deg,#7d7d7d,#0f0f0f); 
}

.card .css { 
    background-image: linear-gradient(180deg,#b758dd,#800191);
}

.card .firebase { 
    background-image: linear-gradient(180deg,#ddc658,#77650d);
}

.card .go{
    background-image: linear-gradient(180deg,#7fd5ea,#006b85); 
}

.card .html {
    background-image: linear-gradient(180deg,#9e58dd,#630191);
}

.card .heroku {
    background-image: linear-gradient(180deg,#ad97c6,#48266e);
}

.card .java { 
    background-image: linear-gradient(180deg,#bf8e6b,#664e01);
}

.card .javascript { 
    background-image: linear-gradient(180deg,#ff7275,#960909);
}

.card .jupyter { 
    background-image: linear-gradient(180deg,#ff9900,#513000); 
}

.card .latex { 
    background-image: linear-gradient(180deg,#56d350,#206601); 
}

.card .photoshop {
    background-image: linear-gradient(180deg,#18a3b2,#004951); 
}

.card .python {
    background-image: linear-gradient(180deg,#7279db,#100070); 
}

.card .react { 
    background-image: linear-gradient(180deg,#a8a8a8,#464949);
}

.card .smtp {
    background-image: linear-gradient(180deg,#c9c9c9,#00345e);
}

.card .sql {
    background-image: linear-gradient(180deg,#336791,#00345e);
}

.card .webDebuggingProxy {
    background-image: linear-gradient(180deg,#ebebeb,#f285f2);
}

.dropdown {
    display:inline-block;
}

.text-gray {
    color: gray !important;
}

.bg-2020 {
    background-color: #828000 !important;
}

.bg-2019, .bg-green, .btn-success {
    background-color: #216834 !important;
}

.bg-2018 {
    background-color: #216768 !important;
}

.bg-2017 {
    background-color: #212968 !important;
}

.bg-2016 {
    background-color: #492168 !important;
}

.bg-2015 {
    background-color: #682160 !important;
}

.bg-placeholder {
    background-color: #682121 !important;
}

.vl {
    border-left: 2px solid gray;
    margin-left: 3%;
    padding-left: 3%;
}